/* ============================================================================= */
/* Clients page. */
/* ============================================================================= */

.sp-clients-grid .sp-clients-block {
  background    : #fff;
  padding       : 2rem;
  margin-bottom : 30px;
  transition    : box-shadow 0.5s;
  will-change   : box-shadow;

  > .client-logo {
    padding-bottom : 1.5rem;
    display        : inline-block;
    max-width      : 100%;
    opacity        : 0.85; 
    transition     : opacity 0.25s;
    will-change    : opacity;
  }

  &:hover {
    box-shadow : 0 0 25px 1px rgba(0, 0, 0, 0.075);
    > .client-logo { opacity : 1; }
  }

  > .client-title { margin-bottom : 1.5rem; }

  .btn { transition-property: background, border; }
}