/*------------------------------------------------------------------
 TABLE OF CONTENTS:

- Base styles for common elements
- Bootstrap style customizations
-   Grid rows
- Primary site preloader
- Scroll top button
- Site pagination
- Site header commons
- Header elements (logo, extras, etc)
- Header desktop navigation
- Fullscreen mobile navigation
- Fullscreen search
- Header stuck state
- Header responsiveness
- Site footer styles
- Page intro block
-   Intro commons
- Portfolio listing
- Testimonials slider
- Theme progress bars
- Pricing tables
- FAQ page
- Clients page
- Mailchimp subscribe block
- 404 page
- Simple text rotator plugin styles
- Typed js integration
- Site socials VC widget
- Visual Composer custom text widget
- Basic blogroll styles
- Blogroll post formats
- Blog sidebar design
- Blogroll single page styles
-------------------------------------------------------------------*/



// ==== variables & deps.
// ==============================
@import 'variables';
@import 'assets/bootstrap/mixins';
@import 'assets/mixins';


// ==== base
// ==============================
@import 'style-parts/base';
@import 'style-parts/bootstrap-customizations';
@import 'style-parts/preloader';
@import 'style-parts/scroll-to-top';
@import 'style-parts/pagination';
@import 'style-parts/slick-slider';


// ==== header, footer
// ==============================
@import 'style-parts/header-base';
@import 'style-parts/header-fn-menu';
@import 'style-parts/header-search';
@import 'style-parts/header-states';
@import 'style-parts/footer';


// ==== uncategorized
// ==============================
@import 'style-parts/intro';
@import 'style-parts/about';
@import 'style-parts/services';
@import 'style-parts/team';
@import 'style-parts/fun_fact';
@import 'style-parts/portfolio';
@import 'style-parts/animated-circle';
@import 'style-parts/testimonials';
@import 'style-parts/contacts';
@import 'style-parts/other';
@import 'style-parts/quote';
@import 'style-parts/progress-bar';
@import 'style-parts/pricing-tables';
@import 'style-parts/faq';
@import 'style-parts/clients';
@import 'style-parts/subscribe';
@import 'style-parts/404';
@import 'style-parts/textrorator';
@import 'style-parts/typedjs';
@import 'style-parts/swipebox';
@import 'style-parts/site-socials';
@import 'style-parts/text-widget';


// ==== blog
// ==============================
@import 'style-parts/blog-base';
@import 'style-parts/blog-post-formats';
@import 'style-parts/blog-sidebar';
@import 'style-parts/blog-single-page';