/* ============================================================================= */
/* Blog sidebar design */
/* ============================================================================= */

.sp-sidebar {
  padding-bottom : 50px;

  a {
    color : #333;
    &:hover { color : $brand-primary; }
  }

  .sp-widget-block {
    @include clearfix;
    margin-bottom : 55px;

    &:last-child { margin-bottom : 0; }

    > .widget-title {
      margin-bottom : 1.5rem;
      font-size     : 1.5rem;
    }
  }
}

.sp-sidebar .sp-category {
  @include reset-box-model(true);

  li {
    padding-bottom : 15px;
    text-align     : left !important;

    span {
      float      : right;
      background : #f5f5f5;
      width      : 30px;
      font-size  : 14px;
      padding    : 1px 0;
      text-align : center;
    }
  }
}

.sp-sidebar .sp-popular-posts {
  @include reset-box-model(true);

  > li {
    list-style    : none;
    margin-bottom : 20px;
    transition    : opacity 0.2s linear;

    &:hover { opacity : 0.7; }
    &:last-child { margin-bottom : 0; }

    > .post-link {
      display : flex;

      > .image { flex : 0 0 100px; }
      > .content { flex : 1; }
    }

    .image {
      padding-right : 15px;
      min-width     : 0;

      > img {
        @include object-fit();
        width  : 100%;
        height : 65px;
      }
    }

    .content {
      text-align : left !important;

      h5 {
        font-size     : 0.92rem;
        margin-bottom : 0;
        line-height   : 1.3;
      }

      span {
        color     : #999;
        font-size : 14px;
      }
    }
  }
}