/* ============================================================================= */
/* Header stuck state. */
/* ============================================================================= */
#sp-header {
  position   : fixed;
  transition : all 0.25s linear;

  a { transition-duration : 0.25s; }

  body.admin-bar & { top: 32px; }
  @media (max-width: 782px) { body.admin-bar & { top: 46px; } }
}

#sp-header.header-stuck {
  position       : fixed;
  padding-top    : 15px;
  padding-bottom : 15px;

  .brand-logo {
    font-size : 2rem;

    .logo-dark { display : block; }
    .logo-white { display : none; }
  }
}

/* ================================= slide-in scroll animation */
#sp-header.stuck-slidein {
  position   : absolute;
  transition : none;

  &:not(.header-stuck) {
    top : 0;
  }

  &.header-stuck {
    position  : fixed;
    animation : sp-header-stuck-slidein 500ms 1 forwards;
  }
}

@keyframes sp-header-stuck-slidein {
  from {
    transform : translate3d(0, -100%, 0);
    opacity   : 0;
  }

  to {
    transform : translate3d(0, 0, 0);
    opacity   : 1;
  }
}

/* ================================= scroll styles */
body:not(.sp-active-burger-menu) #sp-header.header-stuck {
  background          : #fff;
  border-bottom-color : #eee;
  color               : #333;
  box-shadow          : 0 3px 20px 5px rgba(0, 0, 0, 0.1);
}


/* ============================================================================= */
/* Header responsiveness. */
/* ============================================================================= */
@include media-breakpoint-down(lg) {
  #sp-primary-nav > .nav_menu { display : none; }
}

@include media-breakpoint-up(xl) {
  #sp-primary-nav > #sp-mobile-nav-trigger { display : none; }
}

@include media-breakpoint-down(xs) {
  #sp-header { padding : 10px 15px !important; }
  #sp-header-inner > .brand-logo { font-size : 1.6rem !important; padding-right : $grid-gutter-width-base; }
  #sp-header-inner > .header-extras { display : none; }
}