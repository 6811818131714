/* Other Styles */

.sp-slick-clients .item {
  opacity    : 0.65;
  transition : opacity 0.25s linear;
  padding    : 0 15px;
  text-align : center;

  img {
    max-width : 100%;
    height    : auto;
  }

  &:hover { opacity : 1; }

  @include media-breakpoint-down(sm) { opacity: 1; }
}