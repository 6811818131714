/* ============================================================================= */
/* Pricing tables. */
/* ============================================================================= */

.pricing_tables_wrap {
  background : #fff;
  text-align : center;
  border     : 1px solid #eee;
  position   : relative;
  overflow   : hidden;
  transition : box-shadow 0.5s ease;

  > .pricing_tables_name {
    text-align    : center;
    font-size     : 29px;
    color         : $brand-primary;
    padding       : 1rem 0;
    font-weight   : 600;
    border-bottom : 1px solid #eee;
  }

  > .pricing_tables_price {
    text-align    : center;
    font-size     : 65px;
    padding       : 1rem 0;
    border-bottom : 1px solid #eee;
    margin-bottom : 0;

    span {
      color     : #888;
      font-size : 27px;
      position  : relative;
      left      : -7px;
      top       : -29px;
    }

    i {
      color       : #888;
      font-size   : 14px;
      position    : relative;
      top         : 0;
      font-weight : 300;
    }
  }

  > .pricing_tables_desc {
    padding       : 20px 30px;
    border-bottom : 1px solid #eee;
    text-align    : center;

    ul {
      @include reset-box-model(true);

      li {
        display : block;
        padding : 10px 0;
        color   : #888;
      }
    }
  }

  > .pricing_tables_buttons {
    text-align : center;
    padding    : 20px 30px;
    margin     : 0 -10px;

    > a, > .btn, > .btn-group {
      vertical-align : middle;
      margin         : 10px;
    }
  }

  // block states
  &.featured {
    box-shadow : 0 15px 50px -5px rgba(0, 0, 0, 0.25);

    // different shadow:
    // box-shadow: 0 20px 60px rgba(0,0,0,0.15), 0 10px 12px rgba(0,0,0,0.10);

    > .pricing_tables_name { color : #333; }
    > .pricing_tables_price { color : $brand-primary; }
  }

  // uncomment to enable shadow on hover
  // &:not(.featured):hover {
  //    box-shadow : 0 0 30px -5px rgba(0, 0, 0, 0.15);
  // }
}
#sp-pricing-tables-faq {
  h4 {
    margin-bottom: 1.5rem;
  }
  p {
    margin-bottom: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

















