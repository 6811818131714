/* ============================================================================= */
/* Portfolio listing. */
/* ============================================================================= */

.sp-portfolio-block {
  overflow : hidden;
  width    : 100%;

  .sp-portfolio-sorting {
    @include reset-box-model(true);
    margin-bottom : 50px;

    li {
      display : inline-block;

      &:after {
        content   : '/';
        padding   : 0 1rem;
        font-size : 15px;
      }

      &:last-child:after { content : none; }
      a { color : #333; }
      &.active > a { color : $brand-primary; }
    }

    @include media-breakpoint-down(xs) {
      margin-left  : -1rem;
      margin-right : -1rem;

      li:after { display : none; }
      li { padding : 0 1rem; }
    }
  }

  &.inner {
    .sp-portfolio-sorting {
      margin-bottom : 100px;

      li {
        margin : 0 10px 10px 10px;

        > a {
          display       : inline-block;
          border-radius : 4px;
          background    : #eee;
          transition    : background 0.25s linear;
          padding       : 6px 18px;
        }

        &:hover > a,
        &.active > a {
          background: $brand-primary;
          color:#fff;
        }

        &:after {content: none;}
      }
    }

    .sp-portfolio-items {
      margin-left  : -30px;
      margin-right : -30px;
      width        : auto;

      [class^="col-"], [class*=" col-"] { padding-left : 15px; padding-right : 15px; }

      .sp-portfolio-item {
        padding       : 0;
        margin-bottom : 30px;
        &.x2 a { height : 708px; }
        a { height : 340px; }
      }
    }
  }
}

.sp-portfolio-items {
  margin-left  : -17px;
  margin-right : -17px;
  width        : auto;
  [class^="col-"], [class*=" col-"] { padding : 0; }
}

.sp-portfolio-item {
  padding : 2px;
  &.x2 a { height : 704px; }
  a {
    @include cover-bg-base;
    width               : 100%;
    height              : 350px;
    position            : relative;
    display             : block;
    cursor              : pointer;

    .portfolio-hover {
      width      : 100%;
      height     : 100%;
      position   : absolute;
      z-index    : 1;
      top        : 0; left : 0; bottom : 0; right : 0;
      background : rgba(0, 0, 0, 0.2);
      transition : all 0.25s;
      padding    : 50px 50px 0 50px;

      h5 {
        color         : #fff;
        font-size     : 1.4rem;
        margin-bottom : 0;
        text-align    : left;
        position      : relative;
        transform     : translate3d(-20px, 0, 0);
        transition    : all 0.5s;
        opacity       : 0;
      }

      span.desc {
        color      : #fff;
        text-align : left;
        position   : relative;
        display    : block;
        margin-top : 20px;
        width      : 70%;
        transform  : translate3d(-20px, 0, 0);
        transition : all 0.65s;
        opacity    : 0;
      }

      span.link {
        color       : #fff;
        font-size   : 3.3rem;
        text-align  : left;
        position    : absolute;
        bottom      : 30px;
        left        : 50px;
        line-height : 1;
        transform   : translate3d(-20px, 0, 0);
        transition  : all 0.75s;
        opacity     : 0;
      }
    }
  }

  &:hover .portfolio-hover {
    background : rgba(0, 0, 0, .65);

    h5 {
      opacity          : 1;
      transform        : translate3d(0, 0, 0);
      transition-delay : 0s;
    }
    span.desc {
      opacity          : 1;
      transform        : translate3d(0, 0, 0);
      transition-delay : 0.2s;
    }
    span.link {
      opacity          : 1;
      transform        : translate3d(0, 0, 0);
      transition-delay : 0.4s;
    }
  }

  img { max-width : 100%; height : auto; }
}
.sp-portfolio-list {
  &.unlist {
    margin-bottom: 3rem;
    li {
      display: inline-block;
      margin: 0 20px;
      &:last-child {margin: 0 20px}
    }
  }
  ul {padding: 0;margin: 0;
    li {
      list-style: none;
      line-height: 1;
      margin-bottom: 1rem;
      &:last-child {margin: 0;}
      i {font-size: 30px;padding-right: 8px;position: relative; top:5px;}
    }
  }
}
.sp-portfolio-bar-left {
  a {
    color:#333;
    &:hover {
      color: $brand-primary;
      &:before {
        color: $brand-primary;
        transition: color 0.25s linear;}
    }
    &:before {
      font-family: 'sp-theme-icons';
      font-size: 21px;
      color:#333;
      content: '\f3d2';
      top:2px;
      position: relative;
      margin-right: 10px;
      transition: color 0.25s linear;
    }
  }
}
.sp-portfolio-bar-right {
  a {
    color:#333;
    &:hover {
      color: $brand-primary;
      &:after {
        color: $brand-primary;
        transition: color 0.25s linear;}
    }
    &:after {
      font-family: 'sp-theme-icons';
      font-size: 21px;
      color:#333;
      content: '\f3d3';
      top:2px;
      position: relative;
      margin-left: 10px;
      transition: color 0.25s linear;
    }
  }
}
.sp-portfolio-bar-center {
  margin-top: 5px;
  a {
    line-height: 1;
    font-size: 30px;
    color:#333;
    &:hover {
      color: $brand-primary;
    }
  }
}
#sp-single-portfolio {
  .sp-single-heading {
    position: relative;
    h1 {
      position: absolute;
      top:0;
      line-height: 1;
      font-weight: bold;
      &:after {
        font-family: 'sp-theme-icons';
        font-size: 50px;
        color: #333;
        content: '\f3d6';
        padding-left: 30px;
        line-height: 1;
        position: relative;
        top: 3px;
      }
    }
  }  
  h3 {
    margin-top: 5px;
  }
  .sp-single-portfolio-soc {
    margin-top: 2rem;
    ul {
      @include reset-box-model(true);
      font-size  : 0;
      max-width  : none;

      li {
        display : inline;

        a {
          display   : inline-block;
          font-size : 18px;
          color     : #333;
          padding   : 5px 14px;
          &:hover { color : $brand-primary; }
        }
      }
    }
  }
}
