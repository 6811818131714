/* ============================================================================= */
/* Blogroll single page styles. */
/* ============================================================================= */

.sp-intro.single-post {
  text-align : center;

  .intro-title {
    line-height    : 1.3;
    font-size      : 3rem;
    text-transform : capitalize;
  }

  .sp-single-meta {
    margin-bottom : 2rem;
    width         : 100%;
    font-size     : 0;

    ul {
      @include reset-box-model(true);

      li {
        display        : inline;
        font-size      : 18px;
        text-transform : capitalize;

        &:after {
          content : '/';
          padding : 0 10px;
          color   : #fff;
        }

        &:last-child:after { content : none; }

        a { color : #fff; }
      }
    }
  }
}

.sp-blog-block.single {
  .sp-blog-block { margin-bottom : 50px; }

  .sp-blog-image {
    &:hover { opacity : 1; }
    .caption {
      color     : #999;
      font-size : 14px;
      width     : 100%;
    }
  }

  .sp-single-tags {
    font-size : 16px;
    i {
      font-size    : 22px;
      color        : #333;
      position     : relative;
      top          : 1px;
      line-height  : 1.1;
      margin-right : 7px;
    }
    a {
      color         : #333;
      padding-right : 5px;
      &:hover {
        color : $brand-primary;
      }
    }
  }

  .sp-single-soc ul {
    @include reset-box-model(true);
    font-size  : 0;
    margin     : -5px -15px;
    text-align : right;
    max-width  : none;

    li {
      display : inline;

      a {
        display   : inline-block;
        font-size : 18px;
        color     : #222;
        padding   : 5px 15px;
        &:hover { color : $brand-primary; }
      }
    }
  }
}