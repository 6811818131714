/* ============================================================================= */
/* Page intro block. */
/* ============================================================================= */

/* ================================= intro commons */
.sp-intro {
  position    : relative;
  overflow    : hidden;
  text-align  : center;
  width       : 100%;
  color       : #fff;
  text-shadow : 0 1px 3px rgba(0, 0, 0, 0.5);
  user-select : none;

  .intro-bg {
    @include overlay-base();
    @include cover-bg-base();
    z-index : 10;

    &:before {
      @include overlay-base();
      background : #000;
      opacity    : 0.55;
      content    : '';
      z-index    : 15;
    }

    &.no-overlay:before {
      display : none;
    }

    > img {
      @include object-fit();
      width : 100%;
      height : 100%;
    }
  }

  .intro-dotted-bg {
    @include overlay-base();
    background : url('../images/dotted-bg-pattern.png');
    z-index    : 17;
  }

  .intro-body {
    position : relative;
    z-index  : 20;
    padding  : 50px 25px;

    @include media-breakpoint-down(xs) { padding : 50px 15px; }

    h1, h2, h3, h4, h5, h6, p, a { color : #fff; }

    .btn-white {
      color : #333;
    }

    .btn-border {
      border : 2px solid  #fff;
      &:hover {
        color:#333;
      }
    }

    .list-inline {
      margin: 0 -10px -20px;

      li {
        display: inline-block;
        margin: 0 10px 20px;
      }
    }

    p {
      width         : 100%;
      max-width     : 700px;
      margin-left   : auto;
      margin-right  : auto;
      &:last-child { margin-bottom : 0; }
    }

    .btn {
      text-shadow   : none;
      border-radius : 30px;
      padding       : 8px 22px;
      box-shadow    : 0 1px 3px rgba(0, 0, 0, 0.5);
    }

    .sp-intro-image-icon {
      margin-bottom: 2.5rem;
    }

    .icon-big {
      font-size: 90px;
      line-height: 90px;
    }
  }

  &.fullscreen {
    height : 100vh;
    min-height: 450px;
    .intro-body {
      padding  : 75px 25px 75px 25px;
    }
  }

  &.kenburns .intro-bg {
    animation                 : sp-intro-kenburns 30s;
    animation-iteration-count : 1;
    animation-fill-mode       : forwards;
  }

  &:not(.fullscreen):first-child { padding-top: 50px; }
}

@keyframes sp-intro-kenburns {
  0% {
    transform : scale(1);
  }

  100% {
    transform : scale(1.15);
  }
}

.sp-intro .intro-title {
  line-height    : 1;
  font-family    : $headings-font-family;
  //margin-bottom  : $headings-margin-bottom;
  font-weight    : $headings-font-weight;
  text-transform : capitalize;
  margin-bottom  : 2.5rem;

  &:last-child { margin-bottom : 0; }

  @for $i from 1 through 6 {
    &.intro-title-#{$i} { font-size : (4.3rem / $i); }
  }

  @include media-breakpoint-down(sm) {
    @for $i from 1 through 6 {
      &.intro-title-#{$i} { font-size : (4rem / $i / 1.25); }
    }
  }

  @include media-breakpoint-down(xs) {
    @for $i from 1 through 6 {
      &.intro-title-#{$i} { font-size : (4.5rem / $i / 1.5); }
    }
  }
}

.sp-intro .intro-regular-text {
  line-height    : $line-height-base;
  text-transform : inherit;
  margin-bottom  : 2.5rem;
}

.sp-intro .sp-scroll-down-cta {
  position   : relative;
  display    : inline-block;
  height     : 40px;
  width      : 30px;
  margin-top : 2rem;

  i { font-size : 40px; line-height : 1; }

  > span {
    position   : absolute;
    top        : 0;
    left       : 0; width : 100%;
    text-align : center;
    animation  : sp-scrolldown-arrow 2.5s infinite;
  }

  @include media-breakpoint-down(xs) {
    margin-top : 1rem;
  }
}

@keyframes sp-scrolldown-arrow {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 30px);
    opacity: 0;
  }
}


/* ================================= image intro */
.sp-intro.sp-intro-image {
  display         : flex;
  align-items     : center;
  justify-content : center;
}


/* ================================= carousel intro */
.sp-intro.sp-intro-carousel {
  .slider-item {
    position        : relative;
    display         : flex;
    align-items     : center;
    justify-content : center;

    // for the slide transition:
    // opacity         : 0;
    // transition      : opacity 1s;
    // &.slick-active { opacity : 1 }
  }

  &.fullscreen .slider-item {
    height : 100vh; min-height : 450px;
  }

  .slick-arrow {
    transition: opacity 0.25s;
    @include media-breakpoint-down(md) { display : none !important; }
  }

  .slick-dots {
    @include media-breakpoint-down(sm) {display : none !important;}
  }
}


/* ================================= video intro */
.sp-intro.sp-intro-video {
  position        : relative;
  display         : flex;
  align-items     : center;
  justify-content : center;

  > .intro-body { z-index : 30; }

  > .video-container, > .video-container:after {
    @include overlay-base();
  }

  > .video-container:after {
    background : #000;
    opacity    : 0.5;
    content    : '';
    z-index    : 15;
  }

  > .video-container > .video-placeholder {
    @include object-fit();
    width    : 100%;
    height   : 100%;
    position : relative;
    z-index  : 10;
  }

  > .video-container > .video-controls {
    position    : absolute;
    bottom      : 25px;
    right       : 30px;
    margin      : 0 -10px;
    line-height : 1;
    z-index     : 20;
    display     : none;

    > a {
      display   : inline-block;
      min-width : 46px;
      color     : inherit;
      opacity   : 0.5;
      font-size : 28px;
      padding   : 5px 10px;

      &:hover { opacity : 1; }
    }

    @include media-breakpoint-down(sm) { display : none; }
  }
}