/* ============================================================================= */
/* Blogroll post formats. */
/* ============================================================================= */

.sp-blog-block .sp-blog-quote {
  display       : flex;
  border        : 4px solid #eee;
  padding       : 30px;
  margin-bottom : 2rem;

  > .quote-icon {
    flex          : 0 0 auto;
    font-size     : 73px;
    color         : $brand-primary;
    padding-right : 30px;
    line-height   : 1;
    min-width     : 0;
  }

  > .quote-inner {
    flex      : 1 1 auto;
    min-width : 0;

    p { 
      font-size : 1.25rem;
      font-style: italic;
      font-weight: 600;
    }
    .blockquote-footer {
      font-size: 100%;
      cite {
        font-style: normal;
      }
    }
    p:last-child { margin-bottom : 0; }
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    text-align: center;

    > .quote-icon { padding: 0 0 1rem; }
    > .quote-icon, > .quote-inner { flex: 0 0 100%; }

    > .quote-inner p { font-size: 1rem; }
  }
}

.sp-blog-block .sp-blog-link {
  display       : flex;
  border        : 4px solid #eee;
  padding       : 30px;
  margin-bottom : 2rem;
  width         : 100%;
  white-space   : nowrap;
  align-items   : center;

  &:before {
    flex          : 0 0 auto;
    content       : '\f3d9';
    font-family   : "sp-theme-icons";
    font-size     : 70px;
    color         : $brand-primary;
    margin-right  : 30px;
    padding-right : 30px;
    line-height   : 1;
    border-right  : 1px solid #eee;
  }

  a {
    flex          : 1 1 auto;
    min-width     : 0;
    font-size     : 2rem;
    color         : #333;
    display       : inline-block;
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
  }

  @include media-breakpoint-down(md) {
    padding : 20px;
    &:before { font-size : 50px; padding-right : 15px; margin-right : 15px; }
    a { font-size : 1rem; }
  }
}

.sp-blog-block .sp-blog-image {
  .caption {
    display    : block;
    margin-top : 5px;
  }
}

.sp-blog-block .sp-slick-post-gallery {
  .slick-slide .caption {
    margin-top : 5px;
    display    : block;
    opacity    : 0;
    transform  : translateX(15px);
    transition : all 0.25s;
  }

  .slick-slide.slick-animated .caption {
    opacity   : 1;
    transform : translateX(0);
  }
}

.sp-blog-block .sp-single-embed {
  margin-bottom: 2rem;
}











