/* ============================================================================= */
/* Base styles for common elements. */
/* ============================================================================= */

html { -webkit-font-smoothing : antialiased; }

body {
  position       : relative;
  overflow-x     : hidden;
  letter-spacing : 0.04em;

  &::-webkit-scrollbar-track { background : #ddd; }
  &::-webkit-scrollbar { width : 6px; background : #ddd; }
  &::-webkit-scrollbar-thumb { background : $brand-primary; }

  &.disable-hover { pointer-events : none; }
}

p { margin-bottom : 2rem; }
a { transition : all 0.25s; outline: none; }
hr { border-color : #eee; display : inline-block; width : 100%; }
button, input, optgroup, select, textarea { font-family : $font-family-base }

// max-width reset
.wp-caption,
.sp-widget-block img,
.sp-blog-block img { max-width : 100%; height : auto; }

button, input, select, textarea { max-width : 100%; }

#sp-wrapper {background: #fff;position: relative;z-index: 10;}
.form-control {letter-spacing: 0.04em;}
.sp-marg30 {margin-top: 30px;}
.sp-marg50 {margin-top: 50px;}


@include media-breakpoint-down(sm) {
  .text-right {text-align: center !important}
  .text-left {text-align: center !important}
  [class*='col-'] {padding-top: 15px; padding-bottom: 15px;}
  .sp-marg30, .sp-marg50 {margin-top: 30px;}
}


.sp-section {
    &.demo {
      .container {width:1500px;}
      .row {margin-left: -20px; margin-right: -20px;}
      [class*='col-'] {padding-left: 20px; padding-right: 20px;}
    } 
    position: relative;
    z-index: 992;
    padding-top: 110px;
    padding-bottom: 110px;
    background-color: #fff;
    &.pt0 {padding-top: 0px;}
    &.pb0 {padding-bottom: 0px;}
    &.none {padding: 0;}
    &.border {
    	border-top:1px solid #eee;
    }
    &.white {
      color:#fff;
      h1,h2,h3,h4,h5,h6 {color:#fff;}
    }
    &.little {
    	padding-top: 80px;
    	padding-bottom: 80px;
      @include media-breakpoint-down(sm) {padding-top: 50px; padding-bottom: 50px;}
    }
    &.bg-color-light {
    	background: #f5f5f5;
    	border-top:1px solid #eee;
    	border-bottom:1px solid #eee;
    } 
    &.bg-color-dark {
    	background: #333;
    	border-top:1px solid #444;
    	border-bottom:1px solid #444;
    	color:#fff;
    } 
    img {max-width: 100%;}
    @include media-breakpoint-down(sm) {padding-top: 80px; padding-bottom: 80px;text-align: center}
}

.sp-title-block {
  width: 100%;
  margin-bottom: 2rem;
  text-transform: capitalize;
  &.line h3{margin-bottom: 1.4rem;}
  &.line span{margin-bottom: 1.4rem;display: block;}
  &.line:after {
  	content: '';
  	height: 25px;
  	width: 2px;
  	background: $brand-primary;
  	display: inline-block;
  	text-align: center;
  }
}

.sp-overlay {
  @include overlay-base();
  //z-index: -1;
  background: rgba(0, 0, 0, 0.6);
}

.jarallax {
  background-size     : cover;
  background-repeat   : no-repeat;
  background-position : 50% 50%;
}

/* ================================= button vc schortcode */
.sp-btn-container.align_center { text-align : center; }
.sp-btn-container.align_left { text-align : left; }
.sp-btn-container.align_right { text-align : right; }

/* ================================= typed.js integration */
.typed-cursor {
  animation : typed-blink 0.7s infinite;
  opacity   : 1;
  display   : inline-block;
}

@keyframes typed-blink {
  0% { opacity : 1; }
  50% { opacity : 0; }
  100% { opacity : 1; }
}