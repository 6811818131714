/* ============================================================================= */
/* Bootstrap style customizations. */
/* ============================================================================= */

/* ================================= grid rows */
.row { max-width : none !important; }


/* ================================= buttons and other form controls */
.btn {
  cursor : pointer;
}

.btn-lg {
  font-size   : 15px;
  font-weight : 400;
}

.btn-white {
  color            : #333;
  background-color : #fff;
  border-color     : #fff;
}

.form-control:-webkit-autofill { // remove yellow autofill chrome highlight
  -webkit-transition : none;
  -webkit-box-shadow : 0 0 0 30px $input-bg inset;
}


/* ================================= carousels */
.carousel-indicators { margin-bottom : 2rem; }

.carousel-indicators li {
  border-radius    : 50%;
  background-color : $carousel-indicator-active-bg;
  transition       : background-color 0.25s;

  &:hover, &.active {
    background-color : $brand-primary;
  }
}

.carousel-fade .carousel-inner {
  .carousel-item {
    transition-property: opacity;
  }
  .carousel-item,
  .active.carousel-item-left,
  .active.carousel-item-right {
    opacity: 0;
  }
  .active,
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    opacity: 1;
  }
  .carousel-item-next,
  .carousel-item-prev,
  .active.carousel-item-left,
  .active.carousel-item-right {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}


/* ================================= progress bar */
.progress {
  background-color : #ddd;
  border-radius    : 0;

  .progress-bar {
    background-color : #222;
    height           : 5px;
  }
}


/* ================================= input groups */
.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  @include border-radius(0);
}

.input-group .font-size,
.input-group .input-group-addon,
.input-group .input-group-btn {
  z-index : 10;
}