/* ============================================================================= */
/* Primary site preloader. */
/* ============================================================================= */

#sp-preloader {
    @include overlay-base(fixed);
    z-index    : 15000;
    background : #fff;

    &:after {
        content: '';
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px
    }

    &:after {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: inline-block;
        background: $brand-primary;
        animation: 1.15s infinite ease-in-out;
        animation-name: sp-site-loader;

        &:before, &:after {
            animation: 1.15s infinite ease-in-out;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }    
}

@keyframes sp-site-loader {
    from {
        transform: scale(0);
        opacity: 1
    }
    to {
        transform: scale(1);
        opacity: 0
    }
}