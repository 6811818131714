/* ============================================================================= */
/* Site footer styles. */
/* ============================================================================= */

#sp-footer-sizing-helper {
  display : none;
}

#sp-footer {
  background : #222;
  width      : 100%;

  &.sp-footer-fixed {
    position : fixed;
    bottom   : 0; left : 0;

    & + #sp-footer-sizing-helper {
      display : block;
    }
  }

  .container {
    padding-left  : 25px;
    padding-right : 25px;
    .row {
      margin-left  : -25px;
      margin-right : -25px;
      [class*='col-'] {
        padding-left  : 25px;
        padding-right : 25px;
        @include media-breakpoint-down(sm) { padding-top : 25px; padding-bottom : 25px; }
      }
    }
  }
}

#sp-footer > .sp-main-footer {
  position       : relative;
  padding-top    : 100px;
  padding-bottom : 100px;
  border-bottom  : 1px solid #333;
  color          : #fff;

  @include media-breakpoint-down(sm) { padding-top : 80px; padding-bottom : 80px; text-align : center }

  .title-block h4 { color : #fff; }

  .soclink-block {
    ul {
      padding       : 0px;
      margin-top    : 2rem;
      margin-bottom : 0;
      li {
        display       : inline-block;
        list-style    : none;
        padding-right : 20px;
        a {
          color         : $brand-primary;
          border-radius : 50%;
          font-size     : 23px;
          display       : inline-block;
          text-align    : center;
          transition    : all .3s linear;
          &:hover {
            color      : #fff;
            transition : all .3s linear;
          }
        }
      }
    }
  }

  .twitter-block {
    ul { padding-left : 50px; margin : 0;
      li {
        list-style : none; padding-bottom : 1.3rem;
        &:last-child { padding-bottom : 0; }
        &:before {
          content                 : '\f242';
          font-family             : 'sp-theme-icons';
          color                   : #c3ac6d;
          font-size               : 30px;
          -webkit-font-smoothing  : antialiased;
          -moz-osx-font-smoothing : grayscale;
          top                     : 5px;
          display                 : inline-block;
          position                : relative;
          margin-right            : 22px;
          line-height             : 1;
          height                  : 100%;
          margin-left             : -50px;
        }
      }
    }
  }

  .instagram-block {
    margin-left : -5px;

    ul { padding : 0; margin : 0;
      li {
        list-style : none;
        float      : left;
        width      : 25%;
        padding    : 5px;
        img { max-width : 100%; }
      }
    }

    a { display : inline-block; transition-property : opacity; }
    a:hover { opacity : 0.6; }
  }
}

#sp-footer > .sp-end-footer {
  position    : relative;
  padding     : 25px 0;
  color       : #fff;
  font-size   : 0.9rem;
  line-height : 1;

  > .container {
    display     : flex;
    align-items : center;
  }

  a {
    color : #fff;
    &:hover { color : $brand-primary; }
  }

  .menu-block {
    margin-left : auto;

    ul { @include reset-box-model(true); }
    ul li {
      display : inline;

      &:after {
        content : '|';
        padding : 0 10px;
      }

      &:last-child:after { display : none }
    }
  }

  @include media-breakpoint-down(md) {
    text-align : center;
    > .container { flex-wrap : wrap; }
    .end-footer-block { flex-basis : 100%; }
    .end-footer-block + .end-footer-block { padding-top : 20px; }
  }
}