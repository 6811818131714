
/* About Us */
#sp-about p {margin-bottom: 0px;}
span.sp-signature {
  width: 100%;
  font-size: 23px;
  display: block;
  margin-top: 20px;
  font-family: 'Shadows Into Light', cursive;
  font-style: italic;
  letter-spacing: 2px;
  transform: rotate(-3deg) !important;
}