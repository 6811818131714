/* Team */

.sp-team-block {
	margin-top: 10px;
	.image {
		position: relative;
		margin-bottom: 1rem;
		&:before {
			content: '';
			background: rgba(0,0,0,.15);
			left: 0;
			top: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 4px;
			transition: all 0.25s linear;
		}
		img {
			max-width: 100%;
			border-radius: 4px;
		}
		.soc-links {
			position: absolute;
			bottom:0;
			left:0;
			width: 100%;
			ul {
				display: inline-block;
			    text-align: center;
			    padding: 0;
			    margin: 0;
				li {
					float: left;
					opacity: 0;
					visibility: hidden;
					list-style: none; 
					margin: 0 10px;
    				text-align: center;
					font-size: 25px;
    				transition: all 0.25s linear;
    				transform: translate3d(0,20px,0);
    				transition-delay: 0s;
					a {
						color:#fff;
						&:hover {opacity: 0.75;}
					}
				}
			}
		}
	}
	.title h4 {font-size: 21px;margin-bottom: 0.5rem}
	.type {color:#999;}
	&:hover .image {
		ul li {
			transform: translate3d(0,0,0);
			opacity: 1;
			visibility: visible;
			transition: opacity 0.5s, transform 0.5s;
			&:first-child {transition-delay: 0.15s;}
			&:nth-child(2) {transition-delay: 0.20s;}
			&:nth-child(3) {transition-delay: 0.25s;}
			&:nth-child(4) {transition-delay: 0.30s;}
		}
		&:before {
			background: rgba(0,0,0,0.5);
			transition: all 0.25s linear;
		}
	}
}




      




















