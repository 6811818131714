/* Quote */

#sp-quote {
	.sp-signature {font-size: 30px;}
}
.sp-quote {
	font-size: 19px;
	&:before, &:after {
		font-family: "sp-theme-icons";
		font-size: 23px;
		position: relative;
	}
	&:after {
		content: '\e80f';
		left: 15px;
		top: 7px;
	}
	&:before {
		content: '\e80e';
		right: 15px;
	}
}

