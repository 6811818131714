/* ============================================================================= */
/* Basic blogroll styles. */
/* ============================================================================= */

#sp-blog-inner {
	display   : flex;
	flex-wrap : nowrap;

	> .content-column {
		flex      : 1 1 auto;
		min-width : 0;
	}

	> .sidebar-column {
		flex          : 0 0 360px;
		min-width     : 0;
		margin-bottom : -50px;

		&.left { padding-right : 60px; }
		&.right { padding-left : 60px; }
		&:empty { display : none; }
	}

	@include media-breakpoint-down(sm) {
		flex-wrap : wrap;

		> .sidebar-column, > .content-column { flex-basis : 100%; }

		> .content-column { order: 1; }
		> .sidebar-column { margin-top : 80px; order: 2; }

		> .sidebar-column.left { padding-right : 0; }
		> .sidebar-column.right { padding-left : 0; }
	}
}

.sp-blog-block {
	@include clearfix;
	margin-bottom: 55px;

	// styles reset
	p { margin-bottom : 1.5rem; }
	p:last-child { margin-bottom : 0; }

	.sp-blog-image {
		position      : relative;
		margin-bottom : 1.5rem;
		opacity       : 1;
		transition    : opacity 0.2s linear;

		a {
		    background-repeat: no-repeat;
		    background-position: center;
		    background-size: cover;
		    width: 100%;
		    height: 240px;
		    position: relative;
		    display: block;
		    cursor: pointer;
		   	@include media-breakpoint-down(md) {height: 260px;}
		}

		&:hover { opacity: 0.7; }
	}

	.entry-title {
		margin-bottom: 1rem;
		font-size: $font-size-h4;
		a {
			color:#333;
			&:hover {
				color:$brand-primary;
			}
		}
	}

	.sp-blog-meta {
		margin-bottom: 0.5rem;
		width: 100%;
		ul {
			padding: 0;
			margin: 0;
			display: inline-block;
			li {
				float: left;
				list-style: none;
				font-size: 14px;
				text-transform: capitalize;
				&:after {
					content: '/';
					padding: 0 10px;
					color:#999;
				}
				&:last-child:after {content: none;}
				a {
					color:#999;
				}
			}
		}
	}

	&.chess {
	    position: relative;
	    display: table;
	    table-layout: fixed;
	    width: 100%;
	    background-color: #f7f7f7;
	    margin-bottom: 60px;
    	@include media-breakpoint-down(md) {display: block;}
	    .meta-column {
    		padding: 0px 50px;
    		@include media-breakpoint-down(lg) {padding: 50px 30px;}
    		&.inner-col {
    			display: table-cell;
    			width: 50%;
    			vertical-align: middle;
    			@include media-breakpoint-down(md) {
    				width: 100%;
    				display: block;
    			}
    		}
    		h4 {font-size: 1.5rem;}
	    }
		.image-column {
		    position: relative;
		    overflow: hidden;
    		&.inner-col {
    			display: table-cell;
    			width: 50%;
    			vertical-align: middle;
    			@include media-breakpoint-down(md) {
    				width: 100%;
    				display: none;;
    			}
    		}
    		.sp-blog-image {
    			margin-bottom: 0;
	    		a { 
	    			height: 400px;
	    			@include media-breakpoint-down(lg) {height: 350px;}
	    		}
    		}
		}
		&.sticky:nth-child(even) {
			.sp-blog-image {
				&:after {
				    right: 30px;	
				    left:auto;
				}
			  	&:before {
				    right: 30px;
				    left:auto;
				}  		
			}
		}
	}
	&.classic {
		margin-bottom: 80px;
	    display: flex;
	    flex-wrap: nowrap;
		.sp-blog-meta-classic {
		    flex: 0 0 90px;
		    padding-right: 30px;
		    text-align: right;
		    .date {
		    	font-size:1.4rem;
		    	margin-bottom: 0.5rem;
		    }
			.year {
				color:#999;
			}
		}
		.sp-blog-image {
	    	a { 
	    		height: 375px;
	    		@include media-breakpoint-down(lg) {height: 350px;}
	    	}
    	}
	}
	&.medium {
		margin-bottom: 80px;
	    display: flex;
	    flex-wrap: nowrap;
	    @include media-breakpoint-down(md) {display: block}
		.sp-blog-image-medium {
		    flex: 0 0 360px;
		    padding-right: 30px;
		    @include media-breakpoint-down(md) {padding-right: 0;}
			.sp-blog-image {
				margin-bottom: 0;
		    	a { 
		    		height: 275px;
		    		@include media-breakpoint-down(lg) {height: 300px;}
		    		@include media-breakpoint-down(md) {height: 240px;}
		    	}
	    	}
		}
		.sp-blog-block-medium {
			h3 {
				font-size: 1.3rem;
				@include media-breakpoint-down(md) {margin-top: 1rem;}
			}
		}
	}
	&.sticky{
		.sp-blog-image {
			&:after {
				width: 32px;
				height: 48px;
				content: '';
				position: absolute;
				left: 30px;
				top: 0;
				border-bottom: 16px solid transparent;
				border-left: 16px solid $brand-primary;
				border-right: 16px solid $brand-primary;	
			}
			&:before {
				content: 'S';
				font-weight: bold;
				font-size: 19px;
				color: #fff;
				left: 30px;
				position: absolute;
				z-index: 9;
				width: 32px;
				text-align: center;		
			}  		
		}
	}
	&.no-thumbnail.sticky {
		.sp-blog-meta-classic {text-align: center}
		padding: 30px;
		background: #f7f7f7;
	}
}