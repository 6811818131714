/* Fun Fact */

.sp-animate-numbers {
	&.white {
		h2 {color:#fff;}
		p {color:#fff;}
	}
  	h2 {
  		font-size: 55px;
  		line-height: 90px;
  		letter-spacing: 7px;
  		margin-bottom: 0;
  	}
  	p {
  		font-size: 21px;
  		margin-bottom: 0;
  	}
}