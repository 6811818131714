/* ============================================================================= */
/* 404 page. */
/* ============================================================================= */

#sp-404 {
  > .intro-body { padding-top : 50px; padding-bottom : 50px; }

  h1 {
    margin-bottom : 2rem;
    font-size     : 5rem;
    line-height   : 5rem;
  }

  h3 {
    margin-bottom : 3rem;
    font-size     : 3.5rem;
    font-family   : 'Shadows Into Light', cursive;
    transform     : rotate(-2deg) !important;
  }

  .sp-soc-links {
    margin-bottom : 2rem;
    font-size     : 0;

    ul {
      @include reset-box-model(true);
      li { display : inline; }

      li a {
        display   : inline-block;
        padding   : 0 12px;
        font-size : 26px;

        &:hover { color : $brand-primary; }
      }
    }
  }

  .sp-404-menu ul {
    @include reset-box-model(true);
    font-size : 0;

    li {
      display     : inline;
      white-space : nowrap;

      &:after {
        content   : '|';
        display   : inline-block;
        margin    : 0 10px;
        font-size : $font-size-base;
      }

      &:last-child:after { content : ''; }

      a {
        font-size : $font-size-base;
        &:hover { color : $brand-primary; }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    h1 { font-size : 3.8rem; line-height : 3.8rem; padding : 0 15px; }
    h3 { font-size : 3rem; padding : 0 15px; margin-bottom : 2rem; }
    .sp-404-menu ul { padding : 0 15px; }
  }

  @include media-breakpoint-down(xs) {
    h1 { font-size : 3rem; }
    h3 { font-size : 2rem; }
  }
}